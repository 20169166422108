import Vue from 'vue';

export default {

    formatQueryUrl(params) {
        let query = ""
        Object.keys(params).forEach(key => {
            if (key && key != "page" && params[key]) {
                query += `&${key}=${params[key]}`
            }

        });
        return query
    },

    createFormData(objeto) {
        const formData = new FormData();
        for (const chave in objeto) {
            if (objeto[chave]) {
                formData.append(chave, objeto[chave]);
            }
        }
        return formData;
    },

    formatarData(data) {
        if (!data) return null;
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return new Date(data).toLocaleDateString('pt-BR', options).replace(',', '');
    },

    converterData(dataString) {
        if (!dataString) return null;
        const data = new Date(dataString + 'T00:00:00');
        return data;
    },

    converterDataBanco(dataString) {
        if (!dataString) return null;
        const partes = dataString.split(/[-T:]/);
      
        const ano = parseInt(partes[0]);
        const mes = parseInt(partes[1]) - 1;
        const dia = parseInt(partes[2]);
        const hora = parseInt(partes[3]);
        const minuto = parseInt(partes[4]);
        const segundo = parseFloat(partes[5]); 
        
        const data = new Date(ano, mes, dia, hora, minuto, Math.floor(segundo));
      
        return data;
      }
}