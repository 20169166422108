<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Formulário
      <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <form @submit.prevent="formAction" ref="form" data-js="adicionar-externo" class="box">
        <card-component title="Adicionar Empresa" icon="ballot">
          <b-field label="Dados" horizontal>
            <b-field label="Selecione uma Contratante*" :label-position="labelPosition">
              <b-select v-model="form.empresa.empresaIdWaccess" placeholder="Selecione uma Contratante*"
                data-js="contratante" required disabled expanded>
                <option v-for="contratante in getContratantes" :key="contratante.id" :value="contratante.id">
                  {{ contratante.razaoSocial }}
                </option>
              </b-select>
            </b-field>
          </b-field>
          <b-field horizontal>
            <b-field label="Razão Social*" :label-position="labelPosition">
              <b-input v-model="form.empresa.razaoSocial" icon="city-variant-outline" placeholder="Razão Social*" required
                name="razaoSocial" />
            </b-field>
            <b-field label="CNPJ*" :label-position="labelPosition">
              <b-input v-model="form.empresa.cnpj" icon="card-bulleted-outline" placeholder="CNPJ*" name="CNPJ" required
                v-mask="'##.###.###/####-##'" />
            </b-field>
            <b-field label="Logradouro*" :label-position="labelPosition">
              <b-input v-model="form.empresa.logradouro" icon="map-marker-outline" placeholder="Logradouro*"
                name="logradouro" required />
            </b-field>
          </b-field>

          <b-field horizontal>
            <b-field label="Número*" :label-position="labelPosition">
              <b-input v-model="form.empresa.numero" icon="map-marker-outline" placeholder="Numero*" name="nro" required
                type="number" />
            </b-field>
            <b-field label="Bairro*" :label-position="labelPosition">
              <b-input v-model="form.empresa.bairro" icon="map-marker-outline" placeholder="Bairro*" name="bairro"
                required />
            </b-field>
            <b-field label="CEP*" :label-position="labelPosition">
              <b-input v-model="form.empresa.cep" icon="map-marker-outline" placeholder="CEP*" name="CEP"
                v-mask="'#####-###'" required />
            </b-field>
          </b-field>

          <b-field horizontal>
            <b-field label="Selecione o Estado*" :label-position="labelPosition">
              <b-select v-model="form.empresa.uf" placeholder="Selecione o estado*" required expanded>
                <option v-for="(estado, index) in estados" :key="index" :value="estado">
                  {{ estado }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Selecione um Município*" :label-position="labelPosition">
              <b-select expanded v-model="form.empresa.municipio.id" placeholder="Selecione um municipio*" required>
                <option v-for="municipio in getMunicipios" :key="municipio.id" :value="municipio.id">
                  {{ municipio.nome }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Complemento" :label-position="labelPosition">
              <b-input v-model="form.empresa.complemento" icon="map-marker-outline" placeholder="Complemento"
                name="complemento" />
            </b-field>
          </b-field>

          <b-field horizontal>
            <b-field style="width: 400px;" label="Selecione uma Atividade*" :label-position="labelPosition">
              <b-select v-model="form.empresa.atividade.id" placeholder="Selecione uma Atividade*" required>
                <option v-for="atividade in getAtividades" :key="atividade.id" :value="atividade.id" expanded>
                  {{ atividade.descricao }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Telefone*" :label-position="labelPosition">
              <b-input v-model="form.empresa.telefone" icon="phone" placeholder="Telefone*" name="telefone"
                v-mask="'(##) ####-####'" />
            </b-field>
            <b-field label="E-mail*" :label-position="labelPosition">
              <b-input v-model="form.empresa.email" icon="email-outline" placeholder="E-mail*" name="email"
                type="email" />
            </b-field>
          </b-field>

          <b-field horizontal>
            <b-field label="Tipo da Empresa*" :label-position="labelPosition">
              <b-select expanded v-model="form.empresa.tipoEmpresa" placeholder="Selecione o Tipo da Empresa*"
                data-js="tipo-empresa" required disabled>
                <option v-for="(tipo, index) in tipoEmpresa" :key="index" :value="tipo.value">
                  {{ tipo.label }}
                </option>
              </b-select>
            </b-field>

            <b-field label="Limite de Autorizações" :label-position="labelPosition"
              v-if="form.empresa.tipoEmpresa === 'TERMINAL'">
              <b-input v-model="form.empresa.limiteMaxAutoriacaoAprovadas" placeholder="Limte máximo de autorização"
                type="number" name="limite" icon="decimal" required></b-input>
            </b-field>

            <b-field label="Data Validade" :label-position="labelPosition" expanded>
              <b-datepicker v-model="form.empresa.validade" placeholder="Data Validade" expanded icon="calendar-today"
                trap-focus>
              </b-datepicker>
            </b-field>

            <b-switch v-model="form.empresa.ativo" type="is-info">
              Ativo ?
            </b-switch>
          </b-field>

          <hr>
          <b-field horizontal>
            <b-field grouped>
              <div class="control">
                <b-button native-type="submit" type="is-info" :loading="isLoading">
                  Salvar
                </b-button>
              </div>
            </b-field>
          </b-field>

        </card-component>
      </form>
      <card-component title="Adicionar Vínculo ao Pátio" icon="ballot"
        v-if="$route.params.id && form.empresa.tipoEmpresa === 'PATIO_TRIAGEM'">
        <form @submit.prevent="formVinculo">
          <b-field horizontal label="Vincular Terminais">
            <b-field label="Selecione o Terminal" label-position="on-border">
              <b-select placeholder="Selecione um terminal..." v-model="terminalSelecionado" expanded>
                <option v-for="item in getTerminais" :key="item.id" :value="item.id">
                  {{ item.razaoSocial }}
                </option>
              </b-select>
              <div class="control">
                <b-button style="min-width: 200px;" native-type="submit" type="is-info" :loading="isLoading"
                  :disabled="!terminalSelecionado">Adicionar</b-button>
              </div>
            </b-field>
          </b-field>

          <b-field horizontal>
            <b-table :data="getListTerminaisVinculados" striped hoverable style="border: 1px solid rgb(225, 225, 225);"
              height="200px" :sticky-header="true" class="table is-bordered">
              <b-table-column v-slot="terminal" label="Terminal" field="razaoSocial">
                {{ terminal.row.razaoSocial }}
              </b-table-column>
              <b-table-column v-slot="terminal" label="CNPJ" field="cnpj">
                {{ terminal.row.cnpj | VMask('##.###.###/####-##') }}
              </b-table-column>

              <b-table-column v-slot="terminal" label="Ações" custom-key="actions" centered cell-class="is-actions-cell">
                <div class="buttons is-centered no-wrap">
                  <b-tooltip label="Remover" type="is-light">
                    <b-button type="is-danger" size="is-small" @click.prevent="removerVinculoDialog(terminal.row)">
                      <b-icon icon="trash-can" size="is-small" />
                    </b-button>
                  </b-tooltip>
                </div>
              </b-table-column>
              <section slot="empty" class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>Não há dados&hellip;</p>
                </div>
              </section>
            </b-table>
          </b-field>
        </form>
      </card-component>
    </section>
  </div>
</template>
  
<script>
import { defineComponent } from 'vue'
import TitleBar from '@/components/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import FilePicker from '@/components/FilePicker.vue'
import HeroBar from '@/components/HeroBar.vue'
import CheckboxRadioPicker from '@/components/CheckboxRadioPicker.vue'
import EmpresaService from '@/services/EmpresaService.js'
import storage from '@/mixins/storage'

export default defineComponent({
  name: 'AdicionarExterno',
  components: {
    CheckboxRadioPicker,
    HeroBar,
    FilePicker,
    CardComponent,
    TitleBar
  },
  data() {
    return {
      isLoading: false,
      titleStack: ['SECAPI', 'Empresas', 'Adicionar Empresa'],
      tipoEmpresa: [
        { label: 'Contratante', value: 'CONTRATANTE' },
        { label: 'Contratada', value: 'CONTRATADA' },
        { label: 'Terminal', value: 'TERMINAL' },
        { label: 'Patio de Triagem', value: 'PATIO_TRIAGEM' }
      ],
      estados: ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'],
      form: {
        empresa: {
          atividade: { id: null },
          municipio: { id: null },
          validade: null
        },
      },
      atividades: [],
      estado: null,
      municipios: [],
      tipo: null,
      contratante: null,
      contratantes: [],
      pageable: {
        pageNumber: 0
      },
      totalElements: null,
      totalPages: null,
      size: 1000,
      totalElements: null,
      labelPosition: 'on-border',
      terminalSelecionado: null,
      terminaisCombo: [],
    }
  },
  mounted() {
    this.form.tipo = this.$route.query.tipo;
    const tipoEmpresaContratada = document.querySelector('[data-js="tipo-empresa"]')
    const contratante = document.querySelector('[data-js="contratante"]')

    if (this.form.tipo == 'CONTRATADA') {
      contratante.disabled = false;
      this.form.empresa.tipoEmpresa = 'CONTRATADA'
      tipoEmpresaContratada.value = this.form.empresa.tipoEmpresa
    } else {
      tipoEmpresaContratada.disabled = false
    }

    if (this.$route.params.id) {
      this.editar(this.$route.params.id)
    }

    this.$http.get(`/empresas?page=${this.pageable.pageNumber}&size=${this.size}`).then((response) => {
      this.contratantes = response.data.content;

      const roleUserLogado = storage.methods.getRole();
      if (roleUserLogado != 'ROLE_CADASTRO' && roleUserLogado != 'ROLE_ADMIN') {
        const empresaInput = document.querySelector('[data-js="contratante"]')
        empresaInput.disabled = true

        const empresaUserLogado = storage.methods.getEmpresa();

        for (let i = 0; i < this.contratantes.length; i++) {
          if (this.contratantes[i].razaoSocial === empresaUserLogado) {
            this.form.empresa.empresaIdWaccess = this.contratantes[i].id;
            break;
          }
        }

      }
    })

    this.$http.get('/atividades').then((response) => {
      this.atividades = response.data;
    })

    this.$http.get('/municipios').then((response) => {
      this.municipios = response.data;
    })



  },
  methods: {
    formAction() {
      this.isLoading = true
      if (this.form.empresa.id) {

        EmpresaService.putEmpresa(this.form.empresa.id, this.form.empresa)
          .then((response) => {
            this.$buefy.toast.open({
              message: `Empresa Atualizada!`,
              position: 'is-bottom',
              type: 'is-success'
            })

            this.voltar();

          }).catch((error) => {
            if (error.response) {
              this.$buefy.toast.open({
                message: error.response.data.message,
                position: 'is-bottom',
                type: 'is-danger'
              })
              console.log(error.response)
            }
          }).finally(() => {
            setTimeout(() => (this.isLoading = false), 2000);
          })

      } else {

        EmpresaService.postEmpresa(this.form.empresa)
          .then((response) => {
            this.$buefy.toast.open({
              message: `Empresa Cadastrada!`,
              position: 'is-bottom',
              type: 'is-success'
            })

            this.voltar();

          })
          .catch((error) => {
            if (error.response) {
              this.$buefy.toast.open({
                message: error.response.data.message,
                position: 'is-bottom',
                type: 'is-danger'
              })
              console.log(error.response)
            }
          }).finally(() => {
            setTimeout(() => (this.isLoading = false), 2000);
          })
      }

    },

    editar(id) {
      EmpresaService.getEmpresa(id).then((response) => {
        this.form.empresa = {
          ...response.data, atividade: { id: response.data.atividade ? response.data.atividade.id : 1 },
          validade: this.$util.converterData(response.data.validade)
        }

        if (this.form.empresa.tipoEmpresa === 'PATIO_TRIAGEM') {
          this.$http.get(`/empresas?page=0&tipo=TERMINAL`).then((response) => {
            this.terminaisCombo = response.data.content;

          })
        }
      })
    },
    voltar() {
      window.history.back();
    },

    formVinculo() {
      this.isLoading = true
      EmpresaService.postVincularTerminal(this.form.empresa.id, this.terminalSelecionado)
        .then((result) => {
          this.$buefy.toast.open({
            message: 'Terminal Vinculado!',
            position: 'is-bottom',
            type: 'is-success'
          })
          location.reload();
          this.terminalSelecionado = null
        })
        .catch((error) => {
          if (error.response) {
            this.$buefy.toast.open({
              message: `Erro ao vincular Terminal: ${error.response.data.message}`,
              position: 'is-bottom',
              type: 'is-danger',
              duration: 5000
            })
          }
          console.error(error);
        })
        .finally(() => {
          setTimeout(() => (this.isLoading = false), 2000);
        })
    },

    removerVinculoDialog(item) {
      this.$buefy.dialog.confirm({
        title: 'Desvincular Terminal',
        message: `Tem certeza que deseja <b>desvincular</b> este terminal: <b>${item.razaoSocial}</b>?`,
        confirmText: 'Sim',
        cancelText: 'Não',
        type: 'is-danger',
        hasIcon: true,
        size: 'is-small',
        onConfirm: () => this.desvincularTerminal(item.id)
      })
    },

    desvincularTerminal(terminalId) {
      EmpresaService.deleteVinculoTerminal(this.form.empresa.id, terminalId).then(() => {
        this.$buefy.toast.open({
          message: `Terminal Desvinculado!`,
          position: 'is-bottom',
          type: 'is-info'
        })
        location.reload();
      }).catch((error) => {
        this.$buefy.toast.open({
          message: `Houve algum Problema ao desvincular terminal`,
          position: 'is-bottom',
          type: 'is-danger'
        })
        console.error(error)
      })
    }

  },
  computed: {
    getContratantes() {
      return this.contratantes.map((contratante) => {
        return {
          "razaoSocial": contratante.razaoSocial,
          "id": contratante.id
        }
      })
    },

    getAtividades() {
      return this.atividades.map((atividade) => {
        return {
          "codigo": atividade.codigoCnae,
          "descricao": atividade.descCnae,
          "id": atividade.id
        }
      })
    },

    getTerminais() {
      return this.terminaisCombo.map((destino) => {
        return {
          "razaoSocial": destino.razaoSocial,
          "id": destino.id
        }
      })
    },

    getMunicipios() {
      return this.municipios.filter((municipio) => {
        if (municipio.uf === this.form.empresa.uf) {
          return {
            "codigo": municipio.codigo,
            "nome": municipio.nome,
            "uf": municipio.uf,
            "id": municipio.id
          }
        }
      })
    },

    getListTerminaisVinculados() {
      return this.form.empresa.terminais.map((item) => {
        return {
          "razaoSocial": item.razaoSocial,
          "cnpj": item.cnpj,
          "id": item.id
        }
      })
    }
  }
})
</script>
  
<style>
.b-table .table-wrapper.has-sticky-header tr:first-child th {
  background-color: rgb(244, 244, 244);
}
</style>