<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Formulário
      <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <form @submit.prevent="formAction" class="box">
        <card-component :title="title" icon="ballot">
          <b-field label="Dados" horizontal>
            <b-field style="width: 300px;" label="Selecione uma Empresa*" :label-position="labelPosition">
              <b-select v-model="form.veiculo.empresa.id" placeholder="Selecione uma Empresa*" required :disabled="false"
                data-js="empresa">
                <option v-for="empresa in getEmpresas" :key="empresa.id" :value="empresa.id">
                  {{ empresa.razaoSocial }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Tipo Veículo*" :label-position="labelPosition">
              <b-input v-model="form.veiculo.tipo" icon="car" placeholder="Tipo veículo*" name="tipoVeiculo" required
                disabled />
            </b-field>
            <b-field label="Placa Veículo*" :label-position="labelPosition">
              <b-input v-model="form.veiculo.placa" icon="car" placeholder="Placa veículo*" name="placaVeiculo"
                required />
            </b-field>
            <b-field label="Propretário" :label-position="labelPosition">
              <b-input v-model="form.veiculo.proprietario" icon="car" placeholder="Propretário" name="proprietario"
                data-js="proprietario" />
            </b-field>
          </b-field>

          <b-field horizontal>
            <b-field label="Placa Carroceria 1" :label-position="labelPosition">
              <b-input v-model="form.veiculo.placaCarroceria" icon="city-variant-outline" placeholder="Placa carroceria 1"
                name="placaCarroc1" data-js="placaCarroc1" />
            </b-field>
            <b-field label="Placa Carroceria 2" :label-position="labelPosition">
              <b-input v-model="form.veiculo.placaCarroceria2" icon="car" placeholder="Placa carroceria 2"
                name="placaCarroc2" data-js="placaCarroc2" />
            </b-field>
            <b-field label="Placa Carroceria 3" :label-position="labelPosition">
              <b-input v-model="form.veiculo.placaCarroceria3" icon="car" placeholder="Placa carroceria 3"
                name="placaCarroc3" data-js="placaCarroc3" />
            </b-field>
          </b-field>

          <b-field horizontal>
            <b-field label="Marca" :label-position="labelPosition">
              <b-input v-model="form.veiculo.marca" icon="city-variant-outline" placeholder="Marca" name="marca" />
            </b-field>
            <b-field label="Modelo" :label-position="labelPosition">
              <b-input v-model="form.veiculo.modelo" icon="car" placeholder="Modelo" name="modelo" />
            </b-field>
            <b-field label="Cor" :label-position="labelPosition">
              <b-input v-model="form.veiculo.cor" icon="car" placeholder="Cor" name="cor" />
            </b-field>
            <b-field label="Renavam" :label-position="labelPosition">
              <b-input v-model="form.veiculo.renavam" icon="car" placeholder="Renavam" name="renavam" />
            </b-field>
            <b-field label="Ano Fabricação" :label-position="labelPosition">
              <b-input v-model="form.veiculo.anoFab" icon="car" type="number" placeholder="Ano Fabricação" v-mask="'####'"
                name="anoFab" />
            </b-field>
            <b-field label="RNTRC" :label-position="labelPosition">
              <b-input v-model="form.veiculo.RNTRC" icon="car" placeholder="RNTRC" name="RNTRC" />
            </b-field>
          </b-field>

          <b-field horizontal>
            <b-field label="Bengala" :label-position="labelPosition">
              <b-input v-model="form.veiculo.bengala" icon="city-variant-outline" placeholder="Bengala" name="Bengala" />
            </b-field>
            <b-field expanded label="Selecione o Estado*" :label-position="labelPosition">
              <b-select expanded v-model="form.veiculo.uf" placeholder="Selecione o estado*" required>
                <option v-for="(estado, index) in estados" :key="index" :value="estado">
                  {{ estado }}
                </option>
              </b-select>
            </b-field>
            <b-field expanded label="Selecione um Municipio*" :label-position="labelPosition">
              <b-select expanded v-model="form.veiculo.municipio.id" placeholder="Selecione um municipio*" required>
                <option v-for="municipio in getMunicipios" :key="municipio.id" :value="municipio.id">
                  {{ municipio.nome }}
                </option>
              </b-select>
            </b-field>
          </b-field>

          <b-field horizontal>
            <b-field label="Data Inicio Acesso" :label-position="labelPosition">
              <b-datepicker v-model="form.veiculo.dataInicioAcesso" placeholder="Data Inicio acesso" icon="calendar-today"
                trap-focus>
              </b-datepicker>
            </b-field>
            <b-field label="Data Fim Acesso" :label-position="labelPosition">
              <b-datepicker v-model="form.veiculo.dataFimAcesso" placeholder="Data Fim acesso" icon="calendar-today"
                trap-focus>
              </b-datepicker>
            </b-field>
          </b-field>
          <b-field horizontal>
            <b-field label="Data Desligamento" :label-position="labelPosition">
              <b-datepicker v-model="form.veiculo.dataDesligamento" placeholder="Data Desligamento" icon="calendar-today"
                trap-focus>
              </b-datepicker>
            </b-field>
            <b-field label="Área de Acesso" :label-position="labelPosition">
              <b-select expanded v-model="form.veiculo.area" placeholder="Area de Acesso" required
                icon="card-bulleted-outline" data-js="areaAcesso">
                <option v-for="(area, index) in areaAcesso" :key="index" :value="area">
                  {{ area }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Classificação" :label-position="labelPosition">
              <b-select expanded v-model="form.veiculo.classificacao" placeholder="Classificação"
                icon="card-bulleted-outline" data-js="classificacao">
                <option v-for="(item, index) in classificacao" :key="index" :value="item">
                  {{ item }}
                </option>
              </b-select>
            </b-field>
          </b-field>
          <b-field horizontal>
            <b-field label="Justificativa da Visita*" :label-position="labelPosition">
              <b-input v-model="form.veiculo.justificativa" type="textarea" placeholder="Justificativa da visita*"
                maxlength="255" required data-js="justificativa" />
            </b-field>
          </b-field>
          <hr>

          <b-field horizontal>
            <b-field grouped>
              <div class="control">
                <b-button native-type="submit" type="is-info" :loading="isLoading">
                  Salvar
                </b-button>
              </div>
            </b-field>
          </b-field>
        </card-component>
      </form>
    </section>
  </div>
</template>
  
<script>
import { defineComponent } from 'vue'
import TitleBar from '@/components/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import FilePicker from '@/components/FilePicker.vue'
import HeroBar from '@/components/HeroBar.vue'
import CheckboxRadioPicker from '@/components/CheckboxRadioPicker.vue'
import VeiculoService from '@/services/VeiculoService.js'
import storage from '@/mixins/storage'

export default defineComponent({
  name: 'FormVeiculo',
  components: {
    CheckboxRadioPicker,
    HeroBar,
    FilePicker,
    CardComponent,
    TitleBar
  },
  data() {
    return {
      titleStack: ['SECAPI', 'Veículos', this.$route.params.id ? 'Alterar Veículo' : 'Adicionar Veículo'],
      title: this.$route.params.id ? 'Alterar Veículo' : 'Adicionar Veículo',
      areaAcesso: ['PRIMARIA', 'SECUNDARIA'],
      classificacao: ['AUTOMOVEL', 'MOTOCICLETA'],
      estados: ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'],
      isLoading: false,
      form: {
        veiculo: {
          empresa: { id: null },
          municipio: { id: null },
          classificacao: null,
          tipo: null
        },
        tipo: null,
      },
      empresas: [],
      empresas: [],
      municipios: [],
      labelPosition: 'on-border',
      tipoVeiculo: [
        'GERAL',
        'AVULSO'

      ],
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.editar(this.$route.params.id)
    }
    this.$http.get(`/empresas?page=0&size=1000&orderBy=razaoSocial,ASC`).then((response) => {
      this.empresas = response.data.content;

      this.validacao()

    })

    this.$http.get('/municipios').then((response) => {
      this.municipios = response.data;
    })

  },
  methods: {
    formAction() {
      this.isLoading = true
      if (this.form.veiculo.id) {
        VeiculoService.putVeiculo(this.form.veiculo.id, this.form.veiculo).then((response) => {
          this.$buefy.toast.open({
            message: `Veículo Atualizado!`,
            position: 'is-bottom',
            type: 'is-success'
          })
          this.voltar()
        }).catch((error) => {
          if (error.response) {
            this.$buefy.toast.open({
              message: `Ocorreu um erro ao atualizar veículo!`,
              position: 'is-bottom',
              type: 'is-danger'
            })
            console.log(error.response)
          }
        }).finally(() => {
          setTimeout(() => (this.isLoading = false), 2000);
        })
      } else {
        VeiculoService.postVeiculo(this.form.veiculo)
          .then((response) => {
            this.$buefy.toast.open({
              message: `Veículo Salvo!`,
              position: 'is-bottom',
              type: 'is-success'
            })

            this.voltar()
          })
          .catch((error) => {
            if (error.response) {
              this.$buefy.toast.open({
                message: `Ocorreu um erro ao salvar veículo!`,
                position: 'is-bottom',
                type: 'is-danger'
              })
              console.log(error.response)
            }
          }).finally(() => {
            setTimeout(() => (this.isLoading = false), 2000);
          })
      }
    },

    clearDate() {
      this.form.dataDesligamento = null
    },

    editar(id) {
      VeiculoService.getVeiculo(id).then((response) => {
        this.form.veiculo = {
          ...response.data,
          dataDesligamento: this.$util.converterData(response.data.dataDesligamento),
          dataFimAcesso: this.$util.converterData(response.data.dataFimAcesso),
          dataInicioAcesso: this.$util.converterData(response.data.dataInicioAcesso)
        }
      })
    },
    validacao() {
      this.form.veiculo.classificacao = this.$route.query.classificacao;
      this.form.veiculo.area = this.$route.query.area;
      this.form.veiculo.tipo = this.$route.query.tipo;


      if (this.form.veiculo.classificacao == 'MOTOCICLETA') {
        const classificacao = document.querySelector('[data-js="classificacao"]')
        const carrorc1 = document.querySelector('[data-js="placaCarroc1"]')
        const carrorc2 = document.querySelector('[data-js="placaCarroc2"]')
        const carrorc3 = document.querySelector('[data-js="placaCarroc3"]')
        carrorc1.disabled = true
        carrorc2.disabled = true
        carrorc3.disabled = true
        classificacao.disabled = true
        this.form.veiculo.area = 'SECUNDARIA'
      }

      if (this.form.veiculo.area == 'PRIMARIA') {
        const prop = document.querySelector('[data-js="proprietario"]')
        prop.disabled = true
      }

      if (this.form.veiculo.tipo != 'AVULSO') {
        this.form.veiculo.tipo = 'GERAL'
        const justificativa = document.querySelector('[data-js="justificativa"]')
        const areaAcesso = document.querySelector('[data-js="areaAcesso"]')
        justificativa.disabled = true
        justificativa.required = false
        areaAcesso.disabled = true
      }

      const roleUserLogado = storage.methods.getRole();
      if (roleUserLogado != 'ROLE_CADASTRO' && roleUserLogado != 'ROLE_ADMIN') {
        const empresaInput = document.querySelector('[data-js="empresa"]')
        empresaInput.disabled = true

        const empresaUserLogado = storage.methods.getEmpresa();

        for (let i = 0; i < this.empresas.length; i++) {
          if (this.empresas[i].razaoSocial === empresaUserLogado) {
            this.form.veiculo.empresa.id = this.empresas[i].id;
            break;
          }
        }

      }
    },

    voltar() {
      window.history.back();
    }

  },
  computed: {
    getEmpresas() {
      return this.empresas.map((empresa) => {
        return {
          "razaoSocial": empresa.razaoSocial,
          "id": empresa.id
        }
      })
    },
    getMunicipios() {
      return this.municipios.filter((municipio) => {
        if (municipio.uf === this.form.veiculo.uf) {
          return {
            "codigo": municipio.codigo,
            "nome": municipio.nome,
            "uf": municipio.uf,
            "id": municipio.id
          }
        }
      })
    },

    path() {
      const url = window.location.href.split("#/")[1].split("?")
      return url[0]
    },
  }
})
</script>
  