<template>
    <div>
        <title-bar :title-stack="titleStack" />
        <div class="card">
            <header class="card-header">
                <p class="card-header-title"><span class="icon"><i class="mdi mdi-ballot default"></i></span> {{ getTitle()
                }}
                </p>
                <router-link slot="right" :to="`/adicionarPessoa?tipoPessoa=${$route.query.tipoPessoa}`" class="button"
                    style="margin-right: 1.5rem; margin-top: 1rem; margin-bottom: 1rem;">
                    Adicionar
                </router-link>
            </header>

            <div class="card-content">
                <form @submit.prevent="buscar()">

                    <b-field horizontal style="margin-left: -100px;">
                        <b-field label="Empresa">
                            <b-input v-model="filtro.empresa" icon="card-bulleted-outline" placeholder="Digite a Empresa"
                                name="empresa" />
                        </b-field>

                        <b-field label="Nome">
                            <b-input v-model="filtro.nome" icon="card-bulleted-outline" placeholder="Digite o Nome"
                                name="nome" />
                        </b-field>

                        <b-field label="CPF">
                            <b-input v-model="filtro.cpf" icon="card-bulleted-outline" placeholder="Digite o CPF"
                                name="cpf" />
                        </b-field>

                        <b-field label="Status">
                            <b-field>
                                <b-select v-model="filtro.status" placeholder="Status" icon="card-bulleted-outline">
                                    <option v-for="(item, index) in status" :key="index" :value="item.value">
                                        {{ item.label }}
                                    </option>
                                </b-select>
                            </b-field>
                        </b-field>
                        <b-field grouped>
                            <div class="control" style="margin-top: 32px;">
                                <b-button native-type="submit" type="is-info">
                                    Buscar
                                </b-button>
                            </div>
                        </b-field>
                    </b-field>
                </form>
            </div>

            <section class="section is-main-section">
                <card-component class="has-table has-mobile-sort-spaced">
                    <modal-delete-pessoa :is-active="isModalActive"
                        :trash-object-name="trashObject ? trashObject.nome : null" @confirm="trashConfirm"
                        @cancel="trashCancel" />

                    <b-table :checked-rows.sync="checkedRows" :data="pessoasTable" default-sort="name" striped hoverable>

                        <b-table-column v-slot="pessoa" label="Nome" field="nome" sortable>
                            {{ pessoa.row.nome }}
                        </b-table-column>

                        <b-table-column v-slot="pessoa" label="CPF" field="cpf" sortable>
                            {{ pessoa.row.cpf | VMask('###.###.###-##') }}
                        </b-table-column>

                        <b-table-column v-slot="pessoa" label="Empresa" field="empresa" sortable>
                            {{ pessoa.row.empresa }}
                        </b-table-column>

                        <b-table-column v-slot="pessoa" label="Status" field="status" centered sortable>

                            <div>
                                <b-dropdown :triggers="['hover']" aria-role="list" v-model="statusSelecionado"
                                    class="desativado" :disabled="!permitirAlterarStatus()">
                                    <template #trigger>
                                        <span class="tag" style="cursor: pointer;" :class="estiloStatus(pessoa.row.status)"
                                            role="button">
                                            {{ pessoa.row.status }} <b-icon v-if="permitirAlterarStatus()" icon="menu-down"
                                                class="ml-1"></b-icon>
                                        </span>
                                    </template>
                                    <b-dropdown-item v-for="item of listAtualizar" :key="item.value" aria-role="listitem"
                                        @click="atualizarStatus(pessoa.row)" :value="item.value">
                                        <div class="media" :v-slot="item">
                                            <b-icon class="media-left" :icon="item.icon"></b-icon>
                                            <div class="media-content">
                                                <h3>{{ item.label }}</h3>
                                            </div>
                                        </div>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>

                        </b-table-column>

                        <b-table-column v-slot="pessoa" label="Ações" custom-key="actions" cell-class="is-actions-cell">
                            <div class="buttons is-right no-wrap">
                                <b-tooltip label="Editar" type="is-light ">
                                    <router-link
                                        :to="{ name: 'FormPessoa.edit', params: { id: pessoa.row.id }, query: { tipoPessoa: $route.query.tipoPessoa } }"
                                        class="button is-small is-info">
                                        <b-icon icon="pencil" size="is-small" />
                                    </router-link>
                                </b-tooltip>
                                <b-tooltip label="Excluir" type="is-light">
                                    <b-button type="is-danger" size="is-small" @click.prevent="trashModalOpen(pessoa.row)">
                                        <b-icon icon="trash-can" size="is-small" />
                                    </b-button>
                                </b-tooltip>
                            </div>
                        </b-table-column>

                        <section slot="empty" class="section">
                            <div class="content has-text-grey has-text-centered">
                                <p>
                                    <b-icon icon="emoticon-sad" size="is-large" />
                                </p>
                                <p>Não há dados&hellip;</p>
                            </div>
                        </section>
                    </b-table>
                    <br>
                    <section style="margin:15px;">
                        <b-pagination :total="pageable.size" v-model="pageable.current" :range-before="3" :range-after="1"
                            :order="''" :size="''" :simple="false" :rounded="false" :per-page="15"
                            :icon-prev="'chevron-left'" :icon-next="'chevron-right'" aria-next-label="Next page"
                            aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                            :page-input="false" :page-input-position="''" :debounce-page-input="''">

                            <template #default="props">
                                <b-pagination-button :page="props.page" :id="`page${props.page.number}`" tag="router-link"
                                    :to="`/pessoas?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    {{ props.page.number }}
                                </b-pagination-button>
                            </template>


                            <template #previous="props">
                                <b-pagination-button :page="props.page" tag="router-link"
                                    :to="`/pessoas?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    Anterior
                                </b-pagination-button>
                            </template>

                            <template #next="props">
                                <b-pagination-button :page="props.page" tag="router-link"
                                    :to="`/pessoas?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    Próximo
                                </b-pagination-button>
                            </template>
                        </b-pagination>
                        <template>
                            <div>
                                <b>Total de Registros: </b> {{ totalElements }}
                            </div>
                        </template>
                    </section>
                </card-component>
            </section>
        </div>
    </div>
</template>

<script>

import { defineComponent } from 'vue'
import TitleBar from '@/components/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalDeletePessoa from '@/components/ModalDeletePessoa.vue'
import PessoaService from '@/services/PessoaService.js'
import storage from '@/mixins/storage'


export default defineComponent({
    name: "ListPessoa",
    components: {
        TitleBar,
        CardComponent,
        ModalDeletePessoa,
    },
    data() {
        return {
            titleStack: ['SECAPI', 'Pessoas', this.getTitle()],
            status: [
                {
                    label: "APROVADO", value: "APROVADO"
                },
                {
                    label: "CADASTRADO", value: "CADASTRADO"
                },
                {
                    label: "DOCUMENTAÇÃO EXPIRADA", value: "DOCUMENTACAO_EXPIRADA"
                },
                {
                    label: "EMPRESA INATIVA", value: "EMPRESA_INATIVA"
                },
                {
                    label: "PENDENTE", value: "PENDENTE"
                },
                {
                    label: "PENDENTE REMOÇÃO", value: "PENDENTE_REMOCAO"
                },
                {
                    label: "RECUSADO", value: "RECUSADO"
                },
                {
                    label: "REMOVIDO", value: "REMOVIDO"
                },
                {
                    label: "DOCUMENTAÇÃO PENDENTE", value: "DOCUMENTACAO_PENDENTE"
                }
            ],
            filtro: {
                tipoPessoa: this.$route.query.tipoPessoa ?? null,
                empresa: null,
                nome: null,
                cpf: null,
                status: null,
            },
            pessoas: [],
            pageable: {
                current: this.$route.query.page,
                size: 0,
            },
            totalElements: null,
            totalPages: null,
            size: null,
            isModalActive: false,
            trashObject: null,
            checkedRows: [],
            statusSelecionado: null,
            listAtualizar: [
                { label: 'Aprovar', value: 'APROVADO', icon: 'thumb-up' },
                { label: 'Recusar', value: 'RECUSADO', icon: 'thumb-down' },
                { label: 'Cadastro', value: 'CADASTRADO', icon: 'check' }

            ],
        }
    },
    computed: {
        pessoasTable() {
            return this.pessoas.map((pessoa) => {
                return {
                    "id": pessoa.id,
                    "nome": pessoa.nome,
                    "cpf": pessoa.cpf,
                    "empresa": pessoa.empresa.razaoSocial,
                    "status": pessoa.status,
                    "ativo": pessoa.ativo ? "ATIVO" : "INATIVO",
                }
            })
        },
    },
    mounted() {
    },
    methods: {
        buscar() {
            if (this.filtro) {
                this.pageable.current = 1
                let queryFiltro = `pessoas?page=${this.pageable.current}${this.$util.formatQueryUrl(this.filtro)}&orderBy=id,DESC`
                this.$router.push(queryFiltro)
            }
        },
        trashModalOpen(obj) {
            this.trashObject = obj
            this.isModalActive = true
        },
        trashConfirm() {
            this.isModalActive = false
            const id = this.trashObject.id

            PessoaService.deletePessoa(id).then(() => {
                this.$buefy.toast.open({
                    message: `Pessoa Deletada!`,
                    position: 'is-bottom',
                    type: 'is-info'
                })
                location.reload();
            }).catch((error) => {
                if (error.response) {
                    this.$buefy.toast.open({
                        message: `Erro ao deletar Pessoa: ${error.response.data.message}`,
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                }
                console.error(error)
            })
        },
        trashCancel() {
            this.isModalActive = false
        },
        estiloStatus(status) {
            switch (status) {
                case 'APROVADO':
                    return 'is-success'
                case 'CADASTRADO':
                    return 'is-info'
                case 'RECUSADO':
                    return 'is-danger'
                case 'PENDENTE':
                    return 'is-warning'
                case 'PENDENTE_REMOCAO':
                    return 'is-warning'
                case 'DOCUMENTACAO_EXPIRADA':
                    return 'is-warning'
                case 'EMPRESA_INATIVA':
                    return 'is-warning'
                case 'REMOVIDO':
                    return 'is-danger'
            }
        },

        getTitle() {
            var name = this.$route.query.tipoPessoa;
            var title = name === 'VISITANTE' ? 'Visitante' : name === 'MOTORISTA' ? 'Motorista' : 'Funcionário';
            return 'Listar ' + title
        },

        permitirAlterarStatus() {
            const role = storage.methods.getRole();
            return role === 'ROLE_CADASTRO' || role === 'ROLE_ADMIN';
        },

        atualizarStatus(item) {
            this.$buefy.dialog.confirm({
                title: 'Confirmar Alteração',
                message: `Deseja realmente alterar o status de <b>${item.nome}</b> para <b>${this.statusSelecionado}</b> ?`,
                cancelText: 'Cancelar',
                confirmText: 'Confirmar',
                type: 'is-info',
                hasIcon: true,
                onCancel: () => this.statusSelecionado = null,
                onConfirm: () => this.atualizacaoStatusConfirmada(item.id, this.statusSelecionado)

            })
        },

        atualizacaoStatusConfirmada(id, status) {
            this.isLoading = true
            PessoaService.putPessoaStatus(id, status)
                .then((result) => {
                    this.$buefy.toast.open({
                        message: 'Status Atualizado com sucesso!',
                        type: 'is-success',
                        position: 'is-bottom'
                    })
                    location.reload();
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        message: 'Não foi possível completar a operação!',
                        type: 'is-danger',
                        position: 'is-bottom'
                    })
                    console.error(error);
                })
                .finally(() => {
                    this.statusSelecionado = null
                    setTimeout(() => (this.isLoading = false), 500);
                })
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal.query) {

                    this.pageable.current = parseInt(newVal.query.page)

                    PessoaService.getPessoas(this.pageable.current - 1, this.$util.formatQueryUrl(this.$route.query)).then((response) => {
                        this.pessoas = response.data.content;
                        this.pageable.current = response.data.number + 1;
                        this.pageable.size = response.data.totalElements;
                        this.totalPages = response.data.totalPages;
                        this.totalElements = response.data.totalElements;
                    })

                    this.filtro = {}
                    this.filtro.tipoPessoa = this.$route.query.tipoPessoa
                    this.getTitle()
                    this.titleStack = ['SECAPI', 'Pessoas', this.getTitle()]
                }
            },
        },
    }
})

</script>