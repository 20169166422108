<template>
    <div>
        <title-bar :title-stack="titleStack" />
        <div class="card">
            <header class="card-header">
                <p class="card-header-title"><span class="icon"><i class="mdi mdi-ballot default"></i></span> Listar Empresas </p>
                <router-link slot="right" :to="`/adicionarExterno?&${$util.formatQueryUrl($route.query)}`" class="button"
                    style="margin-right: 1.5rem; margin-top: 1rem; margin-bottom: 1rem;">
                    Adicionar
                </router-link>
            </header>

            <div class="card-content">
                <form @submit.prevent="buscar()">
                    <b-field horizontal style="margin-left: -100px;">
                        <b-field label="Empresa">
                            <b-input v-model="filtro.razaoSocial" icon="card-bulleted-outline"
                                placeholder="Digite a Empresa" name="empresa" />
                        </b-field>
                        <b-field label="CNPJ">
                            <b-input v-model="filtro.cnpj" icon="card-bulleted-outline" placeholder="Digite o CNPJ"
                                name="placa" v-mask="'##.###.###/####-##'"/>
                        </b-field>
                        <b-field grouped>
                            <div class="control" style="margin-top: 32px;">
                                <b-button native-type="submit" type="is-info">
                                    Buscar
                                </b-button>
                            </div>
                        </b-field>
                    </b-field>
                </form>
            </div>

            <section class="section is-main-section">
                <card-component class="has-table has-mobile-sort-spaced">
                    <modal-delete-empresa :is-active="isModalActive"
                        :trash-object-name="trashObject ? trashObject.razaoSocial : null" @confirm="trashConfirm"
                        @cancel="trashCancel" />

                    <b-table :checked-rows.sync="checkedRows" :data="externosTable" default-sort="name" striped hoverable>
                        <b-table-column v-slot="empresa" label="Nome" field="razaoSocial" sortable>
                            {{ empresa.row.razaoSocial }}
                        </b-table-column>
                        <b-table-column v-slot="empresa" label="CNPJ" field="cnpj" sortable>
                            {{ empresa.row.cnpj | VMask('##.###.###/####-##')  }}
                        </b-table-column>
                        <b-table-column v-slot="empresa" label="Tipo Empresa" field="tipo" sortable>
                            {{ empresa.row.tipoEmpresa }}
                        </b-table-column>
                        <b-table-column v-slot="empresa" label="Status" field="ativo" sortable>
                            <span v-if="empresa.row.ativo">ATIVO</span>
                            <span v-else>INATIVO</span>
                        </b-table-column>
                        <b-table-column v-slot="empresa" label="Ações" custom-key="actions" cell-class="is-actions-cell">
                            <div class="buttons is-right no-wrap">
                                <b-tooltip label="Editar" type="is-light ">
                                    <router-link
                                        :to="{ name: 'FormExterno.edit', params: { id: empresa.row.id }, query: $route.query }"
                                        class="button is-small is-info">
                                        <b-icon icon="pencil" size="is-small" />
                                    </router-link>
                                </b-tooltip>
                                <b-tooltip label="Excluir" type="is-light ">
                                    <b-button type="is-danger" size="is-small" @click.prevent="trashModalOpen(empresa.row)"
                                        :data-id="empresa.row.id">
                                        <b-icon icon="trash-can" size="is-small" />
                                    </b-button>
                                </b-tooltip>
                            </div>
                        </b-table-column>

                        <section slot="empty" class="section">
                            <div class="content has-text-grey has-text-centered">
                                <p>
                                    <b-icon icon="emoticon-sad" size="is-large" />
                                </p>
                                <p>Não há dados&hellip;</p>
                            </div>
                        </section>
                    </b-table>
                    <br>
                    <section style="margin:15px;">
                        <b-pagination :total="pageable.size" v-model="pageable.current" :range-before="3" :range-after="1"
                            :order="''" :size="''" :simple="false" :rounded="false" :per-page="15"
                            :icon-prev="'chevron-left'" :icon-next="'chevron-right'" aria-next-label="Next page"
                            aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                            :page-input="false" :page-input-position="''" :debounce-page-input="''">

                            <template #default="props">
                                <b-pagination-button :page="props.page" :id="`page${props.page.number}`" tag="router-link"
                                    :to="`/externos?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    {{ props.page.number }}
                                </b-pagination-button>
                            </template>


                            <template #previous="props">
                                <b-pagination-button :page="props.page" tag="router-link"
                                    :to="`/externos?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    Anterior
                                </b-pagination-button>
                            </template>

                            <template #next="props">
                                <b-pagination-button :page="props.page" tag="router-link"
                                    :to="`/externos?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    Próximo
                                </b-pagination-button>
                            </template>
                        </b-pagination>
                        <template>
                            <div>
                                <b>Total de Registros: </b> {{ totalElements }}
                            </div>
                        </template>
                    </section>
                </card-component>
            </section>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import TitleBar from '@/components/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalDeleteEmpresa from '@/components/ModalDeleteEmpresa.vue'
import EmpresaService from '@/services/EmpresaService.js'


export default defineComponent({
    name: "ListExterno",
    components: {
        TitleBar,
        ModalDeleteEmpresa,
        CardComponent,
    },
    data() {
        return {
            titleStack: ['SECAPI', 'Empresas', 'Listar Empresas'],
            filtro: {
                razaoSocial: null,
                cnpj: null,
                tipo: this.$route.query.tipo ?? nul
            },
            totalElements: null,
            totalPages: null,
            size: null,
            isModalActive: false,
            trashObject: null,
            checkedRows: [],
            externos: [],
            pageable: {
                current: 1,
                size: 0,
            },
        }
    },
    mounted() {
    },
    methods: {
        buscar() {
            if (this.filtro) {
                this.pageable.current = 1
                let queryFiltro = `externos?page=${this.pageable.current}${this.$util.formatQueryUrl(this.filtro)}&orderBy=id,DESC`
                this.$router.push(queryFiltro)
            }
        },
        trashModalOpen(empresa) {
            this.trashObject = empresa
            this.isModalActive = true
        },

        trashConfirm() {
            this.isModalActive = false
            const id = this.trashObject.id

            EmpresaService.deleteEmpresa(id).then(() => {
                this.$buefy.toast.open({
                    message: `Empresa Deletada!`,
                    position: 'is-bottom',
                    type: 'is-info'
                })
                location.reload();
            }).catch((error) => {
                this.$buefy.toast.open({
                    message: `Houve algum Problema ao deletar Empresa`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                console.error(error)
            })
        },

        trashCancel() {
            this.isModalActive = false
        },
    },
    computed: {
        externosTable() {
            return this.externos.map((empresa) => {
                return {
                    "id": empresa.id,
                    "razaoSocial": empresa.razaoSocial,
                    "cnpj": empresa.cnpj,
                    "tipoEmpresa": empresa.tipoEmpresa,
                    "status": empresa.ativo ? "ATIVO" : "INATIVO",
                }
            })
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal.query) {
                    this.pageable.current = parseInt(newVal.query.page)

                    EmpresaService.getEmpresas(this.pageable.current - 1, this.$util.formatQueryUrl(this.$route.query)).then((response) => {
                        this.externos = response.data.content;
                        this.pageable.current = response.data.number + 1;
                        this.pageable.size = response.data.totalElements;
                        this.totalPages = response.data.totalPages;
                        this.totalElements = response.data.totalElements;
                    })

                    this.filtro = {}
                    this.filtro.tipo = this.$route.query.tipo ?? null
                }
            },
        },
    }
})
</script>